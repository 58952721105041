import chatRouters from '@/modules/chat/router';
import { createRouter, createWebHistory } from 'vue-router';

const baseRoutes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/IndexView.vue'),
    meta: {
      layout: 'HomeLayout',
      title: 'Chat',
    },
  },
  {
    path: '/error/',
    name: 'error',
    component: () => import('@/views/ErrorView.vue'),
    meta: {
      layout: 'EmptyLayout',
      title: 'Error',
    },
  },
];

const routes = baseRoutes.concat(chatRouters);

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title || to.name} - ${process.env.VUE_APP_TITLE}`;
  next();
});

export default router;
