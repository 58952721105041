export default [
  {
    path: '/chat/',
    name: 'chat',
    component: () => import('../views/ChatView.vue'),
    redirect: { name: 'chat-welcome' },
    children: [
      {
        path: 'welcome',
        name: 'chat-welcome',
        component: () => import('../views/ChatWelcomeFormView.vue'),
        meta: {
          layout: 'EmptyLayout',
          title: 'Chat',
        },
      },
      {
        path: '/chat/:id',
        name: 'chat-form',
        component: () => import('../views/ChatFormView.vue'),
        meta: {
          layout: 'ChatLayout',
          title: 'Chat',
        },
      },
    ],
  },
];
